import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/AccountSlice";
// import bondingReducer from "./slices/BondSlice";
import appReducer from "./slices/AppSlice";
import pendingTransactionsReducer from "./slices/PendingTxnsSlice";
// import poolDataReducer from "./slices/PoolThunk";
// import lusdDataReducer from "./slices/LusdSlice";
import messagesReducer from "./slices/MessagesSlice";
import whitelistReducer from "./slices/WhitelistSlice";

const store = configureStore({
  reducer: {
    account: accountReducer,
    // bonding: bondingReducer,
    app: appReducer,
    pendingTransactions: pendingTransactionsReducer,
    // poolData: poolDataReducer,
    // lusdData: lusdDataReducer,
    messages: messagesReducer,
    whitelist: whitelistReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
