
// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const TOKEN_DECIMALS = 9;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

interface IPresaleAddresses {
  [key: number]: string[];
}

export const presaleAddresses: IPresaleAddresses = {
  56: [
    "0x80e32612638eeAf7E7493eDA4c356421F83ED3e0",
    "0x773562B2Fdf0cC191b142713e37661f1ec24bebd",
    "0x04218a2b3c2E3849Ef0054310F182C026408fB67",
  ],
};
export const addresses: IAddresses = {
  56: {
    DEAD_ADDRESS: "0x0000000000000000000000000000000000000000",
    BUSD_ADDRESS: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", 
    DOHM_ADDRESS: "0x57852e29808a033c99474f93c9c6ffea3bbfb5e6",
  },
}